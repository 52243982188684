import React, { useState, useEffect } from 'react';
import { Container, Typography } from '@mui/material';
import { CSSTransition } from 'react-transition-group';
import './App.css';

const App = () => {
  const [messaggio, setMessaggio] = useState('');
  const [mostraLogo, setMostraLogo] = useState(false);

  useEffect(() => {
    // Simula un caricamento iniziale
    setTimeout(() => {
      setMessaggio('Sei stato hackerato!');
      setTimeout(() => {
        setMessaggio('Non è vero. Però non cliccare mai più.');
        setTimeout(() => {
          setMessaggio('');
          setMostraLogo(true);
        }, 4000);
      }, 4000);
    }, 1000);
  }, []);

  return (
    <Container
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '80vh',
      }}
    >
      <CSSTransition
        in={Boolean(messaggio)}
        timeout={3000}
        classNames="fadeInOut"
        unmountOnExit
      >
        <Typography variant="h3" style={{ fontSize: '4vw' }}>{messaggio}</Typography>
      </CSSTransition>

      <CSSTransition
        in={mostraLogo}
        timeout={0}
        classNames="fadeInOut"
        unmountOnExit
      >
        <img
          src="./Ciruzzo.gif"  // Sostituisci con il percorso della tua GIF
          alt="Logo"
          style={{ maxWidth: '80%', height: 'auto', marginTop: '20px' }}
        />
      </CSSTransition>

    </Container>
  );
};

export default App;